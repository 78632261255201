import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { restClient } from "../axiosInit";

export type Node = {
  public_key: string;
  last_active_date: string;
  ranking: number;
  last_updated_at: string;
};

const ITEMS_PER_PAGE = 20;

const getNodes = async (): Promise<{ data: Node[] }> => {
  return await restClient.get("https://socialchain.app/block_explorer/mainnet_node_selection_ranking");
};

export const useGetNodes = () => {
  const [fetchedNodes, setFetchedNodes] = useState<Node[] | null>(null);
  const [finalNodes, setFinalNodes] = useState<Node[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchingError, setFetchingError] = useState<{ title: string; error: any } | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      try {
        const fetchedNodes = await getNodes();

        if (fetchedNodes.data) {
          setFetchedNodes(fetchedNodes.data);
          setTotalPages(Math.ceil(fetchedNodes.data.length / ITEMS_PER_PAGE));
        }
      } catch (e: any) {
        setFetchingError({ title: t("fetching.error"), error: e });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [t]);

  useEffect(() => {
    if (fetchedNodes) {
      const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
      const paginatedNodes = fetchedNodes.slice(startIndex, startIndex + ITEMS_PER_PAGE);

      setFinalNodes(paginatedNodes);
    }
  }, [currentPage, fetchedNodes]);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return { finalNodes, isLoading, fetchingError, nextPage, prevPage, currentPage, totalPages };
};
