import { StrKey } from "@stellar/stellar-sdk";

import { checkIfMuxedAddress } from "./links";

export const isMuxedAddressOf = (muxedAddress: string, gAddress: string) => {
  if (!checkIfMuxedAddress(muxedAddress)) return false;

  try {
    const rawData = StrKey.decodeMed25519PublicKey(muxedAddress);
    const baseAddress = StrKey.encodeEd25519PublicKey(rawData.slice(0, 32));

    return baseAddress === gAddress;
  } catch (error) {
    console.error("Invalid muxed address:", error);
    return false;
  }
};
