import styled from "styled-components";

export const PageDescription = styled.p`
  white-space: pre-line;
  color: ${({ theme }) => theme.palette.themeColors.fonts.secondaryTitle};
  font-size: ${({ theme }) => theme.fontSize.s};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding-bottom: ${({ theme }) => theme.spacing(8)};
  padding-top: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(8)};
  border-bottom: 1px solid ${({ theme }) => theme.palette.themeColors.borders.searchInput};
`;

export const Rank = styled.p`
  min-width: 80px;
  color: ${({ theme }) => theme.palette.themeColors.fonts.secondaryTitle};
  font-size: ${({ theme }) => theme.fontSize.s};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const PublicKey = styled(Rank)`
  flex: 1;
`;

export const LastActiveDate = styled(Rank)`
  min-width: 200px;
`;

export const RankWrapper = styled.p`
  min-width: 80px;
`;

export const PublicKeyWrapper = styled(RankWrapper)`
  flex: 1;
`;

export const LastActiveDateWrapper = styled(RankWrapper)`
  min-width: 200px;
`;
