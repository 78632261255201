import React from "react";
import { useTranslation } from "react-i18next";

import CustomButton from "../../components/CustomButton/CustomButton";
import LoaderWrapper from "../../components/LoaderWrapper/LoaderWrapper";
import {
  ChangePageButtonsContainer,
  ListContainer,
  ListRow,
  MainContainer,
  TableHeader,
  TableTitles,
  Title,
} from "../../components/ReusableStyles";
import { useGetNodes } from "../../data/services/useGetNodes";
import { formatDateAndTime } from "../../utils/formatDateAndTIme";
import * as S from "./Nodes.styles";

const Nodes = () => {
  const { finalNodes, isLoading, fetchingError, prevPage, nextPage, currentPage, totalPages } = useGetNodes();

  const { t } = useTranslation();

  if (!finalNodes || isLoading || fetchingError)
    return <LoaderWrapper loading={isLoading} fetchingError={fetchingError} />;

  return (
    <MainContainer>
      <TableHeader>
        <Title>{t("nodes").toUpperCase()}</Title>
      </TableHeader>
      <ChangePageButtonsContainer $page={currentPage}>
        {currentPage > 1 && <CustomButton variant="viewAll" label={t("paging.prev")} onClick={prevPage} />}
        {currentPage < totalPages && (
          <CustomButton variant="viewAll" label={t("paging.next")} onClick={nextPage} />
        )}
      </ChangePageButtonsContainer>
      <S.PageDescription>{t("nodes.description")}</S.PageDescription>
      <TableTitles>
        <S.Rank>{t("nodes.rank")}</S.Rank>
        <S.LastActiveDate>{t("nodes.last-active-date")}</S.LastActiveDate>
        <S.PublicKey>{t("nodes.public-key")}</S.PublicKey>
      </TableTitles>
      <ListContainer>
        {finalNodes.map((node, index) => (
          <ListRow $isLast={index === (finalNodes?.length || 0) - 1} key={node.public_key + index}>
            <S.RankWrapper>{node.ranking}</S.RankWrapper>
            <S.LastActiveDateWrapper>
              {formatDateAndTime(node.last_active_date, true)}
            </S.LastActiveDateWrapper>
            <S.PublicKeyWrapper>{node.public_key}</S.PublicKeyWrapper>
          </ListRow>
        ))}
      </ListContainer>
    </MainContainer>
  );
};

export default Nodes;
