import { restClient } from "../axiosInit";

export type MainnetMetricsType = {
  total_locked: number;
  total_supply: number;
  circulating_supply: number;
  migrated_mining_rewards: number;
};

export const getMainnetMetrics = async (): Promise<MainnetMetricsType | null> => {
  try {
    const response = await restClient.get("https://socialchain.app/mainnet_supply");

    return {
      total_locked: response.data.total_locked,
      total_supply: response.data.total_supply,
      circulating_supply: response.data.circulating_supply,
      migrated_mining_rewards: response.data.migrated_mining_rewards,
    };
  } catch {
    return null;
  }
};
