import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import BigNumber from "bignumber.js";
import truncate from "lodash.truncate";
import React from "react";
import { Trans } from "react-i18next";

import { OptionsList } from "../../pages/Home/components/OperationsPanel/components/OptionsList";
import Payment from "../../pages/Home/components/OperationsPanel/components/Payment";
import { isMuxedAddressOf } from "../../utils/isMuxedAddressOf";
import AccountLink from "../AccountLink/AccountLink";
import Offer from "../Offer";
import { TitleWrapper } from "./OperationTitle.styles";

type OperationTitleProps = {
  operation: ServerApi.OperationRecord;
  isAccountTab?: boolean;
  addressInView?: string;
};

const OperationTitle = (p: OperationTitleProps) => {
  if (p.operation.type === "account_merge") {
    const accountLinkButtonVariant =
      p.isAccountTab &&
      (p.addressInView === p.operation.into || isMuxedAddressOf(p.operation.into, p.addressInView || ""));

    return (
      <Trans
        i18nKey="operation.account.merge"
        components={{
          account: (
            <AccountLink
              accountAddress={p.operation.into}
              variant={accountLinkButtonVariant ? "button" : "text"}
            />
          ),
        }}
      />
    );
  }
  if (p.operation.type === "allow_trust") {
    const accountLinkButtonVariant =
      p.isAccountTab &&
      (p.addressInView === p.operation.trustor ||
        isMuxedAddressOf(p.operation.trustor, p.addressInView || ""));

    return (
      <Trans
        i18nKey="operation.trust.allow"
        values={{
          authorize: p.operation.authorize,
        }}
        components={{
          trustor: (
            <AccountLink
              accountAddress={p.operation.trustor}
              variant={accountLinkButtonVariant ? "button" : "text"}
              last
            />
          ),
        }}
      />
    );
  }
  if (p.operation.type === "bump_sequence") {
    return (
      <Trans
        i18nKey="operation.bump"
        values={{
          sequence: p.operation.bump_to,
        }}
      />
    );
  }
  if (p.operation.type === "change_trust") {
    return (
      <Trans
        i18nKey="operation.trust.change"
        values={{
          limit: p.operation.limit,
        }}
      />
    );
  }
  if (p.operation.type === "create_account") {
    const accountLinkButtonVariant =
      p.isAccountTab &&
      (p.addressInView === p.operation.account ||
        isMuxedAddressOf(p.operation.account, p.addressInView || ""));

    return (
      <Trans
        i18nKey="operation.account.create"
        values={{
          balance: new BigNumber(p.operation.starting_balance).toFixed().toString(),
        }}
        components={{
          account: (
            <AccountLink
              accountAddress={p.operation.account}
              variant={accountLinkButtonVariant ? "button" : "text"}
            />
          ),
        }}
      />
    );
  }
  if (p.operation.type === "create_passive_sell_offer") {
    return <Offer operation={p.operation} isAccountTab={p.isAccountTab} addressInView={p.addressInView} />;
  }
  if (p.operation.type === "inflation") {
    return <Trans i18nKey={"operation.inflation"} />;
  }
  if (p.operation.type === "manage_data") {
    return (
      <>
        <Trans
          i18nKey={`operation.manage.data.${p.operation.value ? "remove" : "set"}`}
          values={{
            name: truncate(p.operation.name),
          }}
        />
        {p.operation.value && (
          <Trans
            i18nKey="operation.manage.data.set.to`"
            values={{
              value: truncate(Buffer.from(p.operation.value).toString("base64")),
            }}
          />
        )}
      </>
    );
  }
  if (p.operation.type === "manage_sell_offer") {
    return <Offer operation={p.operation} isAccountTab={p.isAccountTab} addressInView={p.addressInView} />;
  }
  if (p.operation.type === "path_payment_strict_send") {
    return (
      <>
        <Payment operation={p.operation} addressInView={p.addressInView} isAccountTab={p.isAccountTab} />
      </>
    );
  }
  if (p.operation.type === "path_payment_strict_receive") {
    return (
      <>
        <Payment operation={p.operation} addressInView={p.addressInView} isAccountTab={p.isAccountTab} />
      </>
    );
  }
  if (p.operation.type === "payment") {
    return (
      <>
        <Payment operation={p.operation} addressInView={p.addressInView} isAccountTab={p.isAccountTab} />
      </>
    );
  }
  if (p.operation.type === "set_options") {
    return (
      <Trans
        i18nKey="operation.options.set"
        components={{
          options: <OptionsList operation={p.operation} />,
        }}
      />
    );
  }

  return (
    <TitleWrapper>
      <Trans
        i18nKey="operation.unrecognized"
        values={{
          type: p.operation.type,
        }}
      />
    </TitleWrapper>
  );
};

export default OperationTitle;
